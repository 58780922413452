import Link from '../Link';
import React from 'react';
import './ProjectLink.scss';

interface ProjectLinkProps {
  className?: string;
}

const ProjectLink: React.FC<ProjectLinkProps> = ({ className }) => {
  return (
    <Link className={`project-link ${className}`} to="/projects">
      Alle Projekte
    </Link>
  );
};

export default ProjectLink;
