import Layout from '../components/Layout';
import MasonryGallery from '../components/MasonryGallery';
import ProjectLink from '../components/ProjectLink';
import ProjectDescription from '../components/ProjectDescription';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import './project.scss';

interface Props {
  data: any;
}
const ProjectDetail: FC<Props> = ({ data }: Props) => (
  <Layout
    asideNavAdditionalContent={
      <ProjectDescription
        title={data.contentfulProject.projectTitle}
        architect={data.contentfulProject.architectName}
      />
    }
  >
    <MasonryGallery gallery={data.contentfulProject.gallery} />
    <ProjectLink className="project-link-mobile" />
  </Layout>
);

export const query = graphql`
  query ProjectDetailPage($slug: String) {
    contentfulProject(slug: { eq: $slug }) {
      slug
      projectTitle
      architectName
      gallery {
        full: gatsbyImageData(
          height: 1334
          width: 2000
          placeholder: BLURRED
          outputPixelDensities: [0.25, 0.5, 1]
        )
        title
      }
    }
  }
`;

export default ProjectDetail;
