import ProjectLink from '../ProjectLink';
import React, { FC } from 'react';
import './ProjectDescription.scss';
import { useQueryParamString } from 'react-use-query-param-string';

interface Props {
  title: string;
  architect: string;
}

const ProjectDescription: FC<Props> = ({ title, architect }: Props) => {
  const [_, setSlideParam] = useQueryParamString('slide', '');

  return (
    <div>
      <p className="project-title">{title}</p>
      <p className="project-architect">{architect}</p>
      <span className="show-all-button" onClick={(): void => setSlideParam('overview')}>
        Galerie
      </span>
      <ProjectLink className="project-link-desktop" />
    </div>
  );
};

export default ProjectDescription;
